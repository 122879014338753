.container-history-register {
   .container-list-loan {
      grid-template-columns: repeat(4, minmax(0, 1fr));
   }
}

@media screen and (max-width: 600px) {
   .container-history-register {
      .container-list-loan {
         grid-template-columns: repeat(3, minmax(0, 1fr));
      }
   }
   
}

@media screen and (max-width: 500px) {
   .container-history-register {
      .container-list-loan {
         grid-template-columns: repeat(2, minmax(0, 1fr));
      }
   }
   
}

@media screen and (max-width: 400px) {
   .container-history-register {
      .container-list-loan {
         grid-template-columns: repeat(1, minmax(0, 1fr));
      }
   }
   
}
.header-user-layout {
   width: 100%;

   .menu-header-user-layout {
      visibility: hidden;
      display: none;
   }
}

.ant-btn {
   position: static;
}

@media screen and (min-width: 600px) {
   .icon-bars-user-layout {
      visibility: hidden;
      display: none;
   }

   .menu-header-user-layout {
      visibility: visible !important;
      display: flex !important;
   }
}
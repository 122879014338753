.container-register-money {
   .infor-user {
      flex: 3;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: row;
   }

   .infor-loan {
      flex: 1;
   }

   .container_body {
      flex-direction: row;
      padding: 5%;
   }

   .image-component {
      object-fit: fill;
      border-radius: 20px;
      width: 30%;
      height: auto;
      margin-bottom: 21px
   }

   .header-text {
      color: #0147f2;
      font-size: 50px;
      font-weight: bold;
   }

   .bank-component {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
   }

   .logos {
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      background-color: #fff;
      border-radius: 6px;
      //  grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      //  grid-auto-columns: 1fr;
      justify-content: center;
      align-items: center;
      padding: 8px;
      display: grid;
      width: 95%;
      margin-top: 10px;
      margin-bottom: 10px;
   }

   .partner-logo {
      object-fit: scale-down;
      width: 150px;
      height: 50px;
      margin: 0 auto;
      display: inline-block;
      position: static;

   }

   .h2-copy {
      color: #0147f2;
      text-align: center;
      letter-spacing: -3px;
      background-image: none;
      margin-top: 57px;
      padding-bottom: 10px;
      font-family: Bevietnampro, sans-serif;
      font-size: 67px;
      font-weight: 700;
      line-height: 60px;
   }

   .heading-13 {
      color: #333d54;
      text-align: center;
      margin-bottom: 40px;
      padding-top: 20px;
      font-size: 25px;
      font-weight: 400;
   }

   .grid-112 {
      grid-column-gap: 30px;
      grid-row-gap: 30px;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin-top: 51px;
      margin-bottom: 40px;
      padding-left: 20px;
      padding-right: 20px;
      max-width: 80%;
   }

   .box {
      background-color: #fff;
      border-radius: 10px;
      padding: 22px 22px 10px;
      box-shadow: 0 7px 20px rgba(51, 61, 84, .1);
   }

   .numb {
      font-size: 70px;
      font-weight: 400;
      line-height: 90px;
   }

   .heading-14 {
      max-width: 70%;
      font-family: Bevietnampro, sans-serif;
      font-size: 30px;
      line-height: 30px;
   }

   .paragraph-2 {
      color: #586072;
      letter-spacing: -.05em;
      padding-top: 10px;
      font-family: Bevietnampro, sans-serif;
      font-size: 17px;
      font-weight: 500;
      line-height: 25px;
   }

   .w-layout-grid {
      grid-row-gap: 16px;
      grid-column-gap: 16px;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-auto-columns: 1fr;
      display: grid;

   }

   h2 {
      color: #0147f2;
      letter-spacing: -.05em;
      margin-top: auto;
      margin-bottom: auto;
      padding-bottom: 20px;
      font-family: Bevietnampro, sans-serif;
      font-size: 47px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px;
   }

   .body-step-loan {
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
   }

   .max-width-1170 {
      text-align: left;
      background-clip: border-box;
      width: 100%;
      margin: auto;
      padding-top: 0;
      padding-bottom: 0;
      position: relative;
   }


   .w-layout-blockcontainer {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      display: block;
   }

   .heading-140 {
      color: #0147f2;
      text-align: center;
      font-size: 40px;
   }

   .grid-112-copy {
      grid-column-gap: 30px;
      grid-row-gap: 30px;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: 51px;
      margin-bottom: 40px;
   }

   .image-184 {
      width: 90px;
      height: 90px;
      margin-bottom: 26px;
   }

   img {
      vertical-align: middle;
      max-width: 100%;
      display: inline-block;
   }

   h3 {
      color: var(--dark-slate-blue);
      letter-spacing: -.05em;
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: 10px;
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
   }

   p {
      color: #333d54;
      margin-bottom: 20px;
      font-family: Bevietnampro, sans-serif;
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
   }

   .faq-q-text {
      color: #0147f2;
      font-size: 18px;
      font-weight: 600;
   }

   .question {
      width: 80%;
   }

   .question-body {
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      padding-top: 40px;
   }

   .icon-question {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      transition: all 1s ease;
   }

   .icon {
      margin-right: 10px;
   }

   .faq-answer {
      overflow: hidden;
      transition: max-height 1s ease;
      max-height: 0;
   }

   .faq-answer p {
      margin-top: 10px;
   }

   .faq-answer.show {
      max-height: 1000px;
   }

   .footer {
      background-color: #f0f4fe;
      color: #000;
      padding: 20px;
      padding-left: 100px;
      padding-right: 100px;
   }

   .footer-content {
      max-width: 1200px;
      margin: 0 auto;
   }

   .footer p {
      margin-bottom: 10px;
   }

   .footer-social {
      margin-top: 20px;
   }

   .footer-social p {
      margin-bottom: 10px;
   }

   .footer-social svg {
      margin-right: 10px;
      font-size: 24px;
   }

   .footer-text {
      font-family: Bevietnampro, sans-serif;
      font-size: 11px;
   }
}

.title-loan {
   font-size: 22px;
   line-height: 26px;
   color: #fff;
   font-weight: bold;
   padding: 20px 32px;
   border-radius: 12px;
   letter-spacing: 1px;
   margin-bottom: 7px;
   background: #00b4fa;
}

@media screen and (max-width: 768px) {
   .container-register-money {
      .infor-user {
         width: 100%;
         justify-content: center;
         align-items: center;
      }

      .infor-loan {
         width: 100%;
      }

      .container_body {
         flex-direction: column;
         padding: 3%;
      }

      .header-text {
         color: #0147f2;
         font-size: 35px;
         font-weight: bold;
      }

      .image-component {
         display: none;
      }

      .footer {
         background-color: #f0f4fe;
         color: #000;
         padding: 20px;
         padding-left: 20px;
         padding-right: 20px;
      }

      .w-layout-grid {
         display: list-item;
      }

      .box {
         margin-top: 10px;
         margin-bottom: 10px;
      }

      .h2-copy {
         font-size: 50px;
      }

      .heading-13 {
         margin-bottom: 20px;
      }
   }

}
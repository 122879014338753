.container-login {
   background-image: url('../../../assets/images/image-bg.jpg');
   background-size: cover;
   position: relative;

   .form-login {
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(10px);
   }
}
.facebook-login-button {
   background-color: #4267B2; /* Facebook brand color */
   color: white;
   border: none;
   border-radius: 5px;
   font-size: 16px;
   cursor: pointer;
   height: 40px;
   width: 100%;
}

.facebook-login-button:hover {
   background-color: #3B5998; /* Darker shade of Facebook brand color */
}

.textor {
   flex:1;
   justify-content: center;
   align-items: center;
   display: flex;
}

.textstyle {
   text-align: center;
   margin-top: 10px;
   margin-bottom: 10px;
}

.logo{
   position: absolute;
   z-index: 9999;
   width: 36px;
   height: 36px;
   object-fit:contain;
   left: 100;
}
.container-upload {
  .input-type-file {
    display: none !important;
  }

  .btn-multi-upload {
    border: 1px solid #a5a5a5;
    font-size: 13px;
    border-radius: 8px;
    width: 150px;
    height: 28px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    color: #5a5a5a;
  }

  .images-select {
    margin-top: 5px;
    width: 100%;
    // max-height: 300px;
    // overflow-y: auto;
    // overflow-x: hidden;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;

    .item-image {
      width: 150px;
      height: 150px;
      position: relative;
    }
  }
}
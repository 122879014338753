.container-register-information {
   background-image: url("../../../assets/images/image-bg.jpg");
   background-size: cover;

   .form-register-information {
      // background-color: rgba(255, 255, 255, 0.8);
      // backdrop-filter: blur(10px);
   }
}

.infor-user {
   width: 50%;
}

.infor-user2 {
   flex: 1;
}

.image-user {
   width: 100%;
   box-sizing: border-box;
}


@media screen and (max-width: 600px) {
   .infor-user {
      width: 100%;
   }

   .infor-user2 {
      width: 100%;
   }
}